/*************************************************
 * nombre:       Constantes
 * descripcion:
 *
 * librerias:
 * props:
 * tiempo        10 min
 *************************************************/

 export const COTIZACION = "cotizacion";
 export const COTIZACIONES = "cotizaciones";
 export const ABONOS = "abonos";
 /////////////////////////////
 export const RUTAS = "rutas";
 export const USUARIOS = "usuarios";
 export const SALUDOS = "saludos";
 export const CONSECUTIVOS = "consecutivos";
 export const OBRAS = "obras"
 export const RECIBOS = "recibos"
 export const CONFIGURACIONES = "configuraciones"
 export const PLANES = "planes";
 export const LISTAPAGOS = "listaPagos";
 export const ANUNCIOS = "anuncios";
 
 
 export const CDirige = ["camas", "quehacer", "reglas","nosotros","contacto"]
 
 export const CCredenciales = ["Clientes", "Cobros", "Planes", "Usuarios", "Recibos"]