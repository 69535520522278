/**************************************************
 * Nombre:       SQueHacer
 * Descripcion:
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {useState} from 'react';
import {Button, Grid, Typography} from "@mui/material";
import CardRutas from "../Cards/CardRutas";


const SQueHacer = () => {
    const [rutas, setRutas] = useState([])

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{backgroundColor: "pink", width: "100%"}}
        >

            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                sx={{marginTop: 4}}
            >

                <Grid item lg={6} sm={12} xs={12}>
                    <Typography sx={{fontSize: 24, fontWeight: 600}}>Que Hacer</Typography>
                </Grid>

                <Grid item container lg={6} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                    <Button>Agregar </Button>
                </Grid>

                <Grid item container>

                    {rutas.map((item) => {
                        return (
                            <Grid item lg={12} sm={12} xs={12}>
                                <CardRutas item={item}/>
                            </Grid>
                        )
                    })}


                </Grid>


            </Grid>


        </Grid>
    )
}

export default SQueHacer;