/*************************************************
 * nombre:       Login
 * descripcion:
 *
 * librerias:
 * props:
 * tiempo:       1 hora
 *************************************************/
import {useContext, useState} from "react";
import {Button, ButtonBase, Dialog, Grid, TextField, Typography} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import logo from '../../Recursos/logolargo.svg'
import {getAuth, signInWithEmailAndPassword, signOut} from "firebase/auth";
import {app, fire} from "../../fire";
import {obtenerID} from "../../FuncionesGlobales";
import {getDoc, doc} from "firebase/firestore";
import {USUARIOS} from "../../Constantes";
import {UsuarioDoc} from "../../Entidades/Usuario";
import {CRoot} from "../../App";


const Login = () => {
    const navigate = useNavigate();
    ///////////////////////////////////////
    const [email, setEmail] = useState('');
    const [pass, setPass] = useState('')
    const cData = useContext(CRoot);

    const salir = () => {
        let aunth = getAuth(app);
        signOut(aunth).then((uss) => {
            cData.setUsuario(null)
            navigate('/')
        })
    }

    const loguear = () => {

        if (email !== '' && pass !== '') {
            let aunth = getAuth(app);

            signInWithEmailAndPassword(aunth, email, pass).then((uss) => {


                if (uss.user.email !== null) {
                    let id = obtenerID(uss.user.email)
                    getDoc(doc(fire, USUARIOS, id)).then((document) => {
                        if (document.exists()) {
                            let usu = new UsuarioDoc(document);
                            cData.setUsuario(usu)
                        }
                    })
                }

            }).catch((err) => {
                alert(err.message)
            })
        } else {
            alert("correo y contraseña es necesario para esta accion")
        }

    }

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            sx={{minHeight: "100vh"}}
        >


            <Dialog open={true} maxWidth={"xs"} fullWidth>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{p: 2}}
                >

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "flex-end"}}>
                        <Grid item container sx={{justifyContent: "flex-end"}}>
                            <ButtonBase onClick={() => salir()}>
                                <Typography>Ir a home</Typography>
                            </ButtonBase>
                        </Grid>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center", marginTop: 4}}>
                        <img src={logo} width={"60%"}/>
                    </Grid>

                    <Grid item container lg={12} sm={12} xs={12} sx={{justifyContent: "center"}}>

                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            sx={{px: 6}}
                        >


                            <Grid item container sx={{marginTop: 8}}>
                                <TextField variant={"filled"} color={"secondary"} label={"Correo"} size={"small"}
                                           fullWidth
                                           value={email}
                                           onChange={(e) => setEmail(e.target.value)}

                                />
                            </Grid>

                            <Grid item container sx={{marginTop: 3}}>
                                <TextField variant={"filled"} color={"secondary"} label={"Constraseña"} size={"small"}
                                           fullWidth
                                           value={pass}
                                           onChange={(e) => setPass(e.target.value)}

                                />
                            </Grid>

                            <Grid item container sx={{marginTop: 2, justifyContent: "flex-end"}}>
                                <ButtonBase>
                                    <Typography sx={{fontSize: 14}}>Olvide mi contraseña</Typography>
                                </ButtonBase>
                            </Grid>

                            <Grid item container sx={{justifyContent: "center", marginTop: 8, marginBottom: 4}}>
                                <Button variant={"contained"} color={"secondary"}
                                        onClick={() => loguear()}
                                >ENTRAR</Button>
                            </Grid>

                        </Grid>
                    </Grid>

                </Grid>
            </Dialog>

        </Grid>
    );
};
export default Login;
