/**************************************************
 * Nombre:       CardRutas
 * Descripcion:  
 *
 * Libreria:
 *
 * Tiempo:     1 Hr
 **************************************************/
import {React} from 'react';
import {Grid} from "@mui/material";


const CardRutas = () => {

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >


        </Grid>
    )
}

export default CardRutas;