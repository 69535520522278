import {Grid, Typography, useMediaQuery} from '@mui/material'
import React from 'react'
import {Habitacion} from './Components/Habitacion'
import ButtonReservar from './Components/ButtonReservar'
import {AnimApareceAbajo} from '../../../Animadores/Animadores'
import {theme} from "../../../Tema";

const ContentHabitacion = (props) => {
    const {inverse, image, titulo, contenido, valor} = props
    const masSM = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
            {
                masSM ?
                    <>
                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            lg={6}
                        >
                            {
                                inverse
                                    ?
                                    <Habitacion image={image} inverse={inverse} titulo={titulo}
                                                valor={valor}/>
                                    :
                                    <AnimApareceAbajo>
                                        <Typography sx={{
                                            fontSize: masSM ? 40 : 30,
                                            fontWeight: 'medium',
                                            textAlign: 'center',
                                            mt: 6
                                        }}>{titulo}</Typography>
                                        <Typography sx={{
                                            fontSize: masSM ? 20 : 17,
                                            mt: 2,
                                            px: masSM ? 10 : 10,
                                            textAlign: 'center',
                                            mb: 10,
                                            fontWeight: 'light'
                                        }}>
                                            {contenido}
                                        </Typography>

                                        <ButtonReservar/>
                                    </AnimApareceAbajo>


                            }

                        </Grid>

                        <Grid
                            item
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            lg={6}
                            sx={{mt: inverse ? 5 : -5}}
                        >
                            {
                                inverse
                                    ?
                                    <AnimApareceAbajo>
                                        <Typography sx={{
                                            fontSize: masSM ? 40 : 30,
                                            fontWeight: 'medium',
                                            textAlign: 'center',
                                            mt: 6
                                        }}>{titulo}</Typography>
                                        <Typography sx={{
                                            fontSize: masSM ? 20 : 17,
                                            mt: 2,
                                            px: masSM ? 10 : 10,
                                            textAlign: 'center',
                                            mb: 10,
                                            fontWeight: 'light'
                                        }}>
                                            {contenido}
                                        </Typography>

                                        <ButtonReservar/>
                                    </AnimApareceAbajo>
                                    :
                                    <Habitacion inverse={inverse} image={image} titulo={titulo}
                                                valor={valor}/>


                            }

                        </Grid>
                    </>
                    :
                    <Grid
                        item
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        lg={6}
                    >

                        <Habitacion inverse={inverse} image={image} titulo={titulo}
                                    valor={valor}/>

                        <AnimApareceAbajo>
                            <Typography
                                sx={{fontSize: masSM ? 40 : 30, fontWeight: 'medium', textAlign: 'center', mt: 6}}>
                                {titulo} </Typography>
                            <Typography sx={{
                                fontSize: masSM ? 20 : 17,
                                mt: 2,
                                px: masSM ? 30 : 10,
                                textAlign: 'center',
                                mb: 10,
                                fontWeight: 'light'
                            }}>
                                {contenido}
                            </Typography>

                            <ButtonReservar/>
                        </AnimApareceAbajo>


                    </Grid>

            }
        </Grid>
    )
}

export default ContentHabitacion
