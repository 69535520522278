/*******************************************************
 * Nombre:    Dashboard
 * Resumen:   Panel de control donde se mostrara las
 *            secciones deacuerdo a user.credenciales
 * (array de strings)
 * Props: (por context)
 *  usuario -> custom object usuario
 *  proyecto -> objeto que contiene los datos del
 *              proyecto que se mostraran
 * Librerias:
 * Autor:    Luis Rosero
 * Tiempo :  4 hora
 ********************************************************/
import {createContext, React, useContext, useState} from 'react';
import {Button, Drawer, Grid, useMediaQuery} from '@mui/material';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {CRoot} from "../App";
import {theme} from "../Tema";
import AppBarCustom from "./Componentes/AppBarCustom";
import MenuLateral from "./Componentes/MenuLateral";
import {PRIMARIO} from "../ColoresContantes";
import SQueHacer from "./SQueHacer/SQueHacer";


const Dashboard = () => {
    const cData = useContext(CRoot);
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));
    const anchoDrawer = 240;
    const altoBarra = 60;
    //////////////////////////////////////////////////////////////


    return (
        <>

            <Router>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"

                >
                    <Grid item container>

                        <AppBarCustom alto={altoBarra}/>


                    </Grid>

                    <Drawer
                        variant={matchUpMd ? 'persistent' : 'temporary'}
                        anchor="left"
                        open={cData.openDrawer}
                        onClose={() => cData.cerrarDrawer()}
                        color="inherit"
                        PaperProps={{
                            style: {
                                width: anchoDrawer,
                                border: 'none',
                                zIndex: 100,
                                backgroundColor: PRIMARIO
                            },
                        }}
                    >

                        <MenuLateral/>


                    </Drawer>
                </Grid>
                <main
                    style={{
                        width: "100%",
                        minHeight: "91vh",
                        paddingTop: matchUpMd ? 0 : 0,
                        paddingBottom: matchUpMd ? 0 : 0,
                        paddingLeft: matchUpMd ? 20 : 10,
                        paddingRight: matchUpMd ? 20 : 10,
                        marginLeft: cData.openDrawer && matchUpMd ? anchoDrawer : '0px',
                        transition: theme.transitions.create('margin', {
                            easing: theme.transitions.easing.easeOut,
                            duration: theme.transitions.duration.enteringScreen,
                        }),
                    }}
                >


                    <Routes>


                        <Route path="/QueHacer" element={<SQueHacer/>}> </Route>

                        <Route path="/QueHacer" element={<SQueHacer/>}> </Route>


                    </Routes>


                </main>

            </Router>

        </>
    );
};

export default Dashboard;

