  /*******************************************************
 * Nombre:      Colores
 * Descripcion:
 *
 * Props:
 * Librerias:
 * Tiempo :      10 min
 ********************************************************/
 export const PRIMARIO = "#663419"
 export const ACENTO = "#F4EFE2"
 export const FONDO = "#FFFFFF"