/*******************************************************
 * Nombre:    AppBarCustom
 * Resumen:   Barra de herramientas creada, maneja la
 *            apertura de menu lateral y salida del
 * sistema
 * Props:
 * Librerias:
 * Autor:     Luis Rosero
 * Tiempo :   45 min
 ********************************************************/
import {React, useContext, useState} from 'react';
import {Avatar, ButtonBase, Grid, IconButton, Menu, useMediaQuery} from '@mui/material';

import {HambergerMenu, Logout} from "iconsax-react";
import logo from '../../Recursos/logolargoblanco.svg'
import {getAuth} from "firebase/auth";
import {app} from "../../fire";
import {CRoot} from "../../App";
import {theme} from "../../Tema";
import {PRIMARIO} from "../../ColoresContantes";


const AppBarCustom = (props) => {
    const {alto} = props;
    const cData = useContext(CRoot);
    const sCell = useMediaQuery(theme.breakpoints.only("xs"));


    const salir = () => {
        getAuth(app).signOut().then((dox) => {
            cData.setUsuario(null)
        })
    };
    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{paddingX: 2, boxShadow: 0, height: alto, backgroundColor: PRIMARIO}}
        >


            <Grid item lg={6} sm={6} xs={8}>
                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                >

                    <Grid item lg={1} sm={1} xs={2}>


                        <ButtonBase sx={{p: 0.5, borderRadius: 2}} onClick={() => cData.abrirDrawer()}>
                            <HambergerMenu color={"#fff"} size={"1.5rem"}/>
                        </ButtonBase>
                    </Grid>

                    {!sCell &&
                    <Grid item lg={4} sm={5} xs={4}>
                        <img src={logo} width={"70%"}/>
                    </Grid>
                    }


                </Grid>
            </Grid>

            <Grid item container lg={6} sm={6} xs={2} sx={{justifyContent: "flex-end"}}>
                <ButtonBase sx={{p: 0.5, borderRadius: 2}} onClick={() => salir()}>
                    <Logout variant={"Bold"} color={"#fff"} size={"1.5rem"}/>
                </ButtonBase>
            </Grid>


        </Grid>
    );
};

export default AppBarCustom;
